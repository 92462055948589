
module.exports = function ProjectRollovers ($host, config) {

  // Reference

  var dimmedClass = 'is-dimmed';
  var activeClass = 'is-active';


  // Dom

  var $triggers  = $('[data-rollover-target]', $host);
  var $rollovers = $('[data-rollover]',        $host);


  // Functions

  function undimAll () {
    $triggers.removeClass(dimmedClass)
  }

  function dimAllButThis (target) {
    $triggers
      .removeClass(dimmedClass)
      .filter(function (ix, el) { return el !== target; })
      .addClass(dimmedClass);
  }

  function showMatchingRollover (rolloverIx) {
    $rollovers
      .removeClass(activeClass)
      .filter(function (ix, el) { return el.dataset.rollover == rolloverIx; })
      .addClass(activeClass);
  }


  // Listeners

  $triggers.on('mouseenter', function () {
    dimAllButThis(this);
    showMatchingRollover(this.dataset.rolloverTarget);
  });

  $triggers.on('mouseleave', function () {
    undimAll();
    showMatchingRollover(-1);
  });

}

