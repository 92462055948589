
//
// Import Controller Library
//

var Controllers = {
  'global':  require('./global'),
  'filter':  require('./filter'),
  'panes':   require('./panes'),
  'product': require('./product'),
  'debug':   require('./debug'),
  'cart':    require('./cart'),
};


//
// Controller Detection and Initialisation
//

// Meta

var global = Controllers.global();


// Functions

window.initController = function initController (type, $host) {
  if (Controllers[type]) {
    var ctrl = Controllers[type]($host, findInside($host));
    global.onControllerAvailable(type, ctrl);
  } else if (type !== '') {
    console.warn('No such controller:', type);
  }
}

window.initControllerOf = function initControllerOf ($host) {
  return function _initControllerOf (type) {
    initController(type, $host);
  };
}


// Export

module.exports = {
  initController: initController,
  initAllWithin: function initControllers (subdom) {
    var selection = subdom.querySelectorAll('[data-controller]');
    [].forEach.call(selection, function (el) { // Cos Edge doesn't have forEach on Nodelists
      el.dataset.controller.split(' ').forEach(initControllerOf($(el)));
    });
  },
};

