
var dimClass = 'is-dimmed';

var staggerTime = 0.04;
var fadeTime    = 0.1;


function sortByKeywordThenIx (keyword) {
  // If both items match the keyword, resolve by original index
  // If A or B alone have the keyword, that one wins
  // If neither match the keyword, resolve by index again

  return function _sortByKeywordThenIx (a, b) {
    return a.dataset.keyword === keyword && b.dataset.keyword === keyword ? a.dataset.ix - b.dataset.ix
         : a.dataset.keyword === keyword ? -1
         : b.dataset.keyword === keyword ?  1
         : a.dataset.ix - b.dataset.ix;
  };
}

function toggleOnKeywordMatch (keyword) {
  return function _toggleOnKeywordMatch () {
    $(this).toggleClass(dimClass, this.dataset.keyword !== keyword);
  };
}

function cascadeOpacity ($items, destAlpha, λ) {
  var max = $items.length;
  for (var i = 0; i < max; i++) {
    delay(i * staggerTime, function (i) {
      return function () {
        $items.eq(i).animate({ opacity: destAlpha }, fadeTime * 1000);
      }
    }(i));
  }
  delay(max * staggerTime, λ || id);
}


//
// Filterable List of Items with Keywords
//

module.exports = function FilterList ($host, config) {

  // Dom
  var $items = $('[data-keyword]', $host);

  // State
  var state = { ready: true };

  // Functions
  function filterByKeyword (keyword) {
    if (!state.ready) {
      red('FilterList::filter - not yet ready to filter by', keyword);
      return false;
    }

    blue('FilterList::filter - filtering list by', keyword);

    state.ready = false;

    // Create host for duplicate list
    var $newHost = $('<div></div>');
    $newHost.css({ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' });

    // Sort a copy of the entries
    var $sortedItems = $($items.clone().toArray().sort(sortByKeywordThenIx(keyword)));

    // Copy entries to new host in sorted order and hide them
    $sortedItems.css('opacity', 0).appendTo($newHost);

    // Add dimming classes where necessary to cloned items
    if (keyword === '' || keyword === '*' || keyword === 'all') {
      $sortedItems.removeClass(dimClass);
    } else {
      $sortedItems.each(toggleOnKeywordMatch(keyword));
    };

    // Append the new host over top the old one
    $host.append($newHost);

    // Disappear original list.
    // When the list is disappeared, delete those items and reify the cloned
    // list as the real list
    cascadeOpacity($items, 0, function () {
      $items.remove();
      $newHost.css('position', 'static');
    });

    // Part-way through the old list vanishing, begin un-vanishing the new list.
    // When the new list is un-vanished, promote the children of the new host
    // into the original host proper and update the canonical items reference
    // Finally, reset the ready state to true so we can accept new filter requests.
    delay(2 * staggerTime, function () {
      cascadeOpacity($sortedItems, 1, function () {
        $host.append($sortedItems);
        $newHost.remove();
        $items = $host.children();
        state.ready = true;
        blue('FilterList::filter - ready');
      });
    });

    return true;
  }


  // Interface
  return {
    filter: filterByKeyword
  };

};

