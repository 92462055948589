
// Setup

var collapsedClass  = 'is-collapsed';
var collapsingClass = 'is-collapsing';
var collapseSpeed   = 1.25;
var collapsedHeight = { small: 37, big: 57 };
var imageLinkTouchClass = 'touched';
const ease = Power3.easeOut;
const time = 0.5;

function isBig () { return window.innerWidth > 540 };

// Helpers

function getCollapsedHeight () {
  return collapsedHeight[ isBig() ? 'big' : 'small' ];
}

function releaseHeight () {
  $(this).css('height', '');
}

function removeTarget () {
  this.target.remove();
}


//
// Pane Widgets
//

module.exports = function Pane ($host, config, onClickHandlers) {

  // Dom

  var $header = $host.find('.pane-header');
  var $inner  = $host.find('[data-pane-content]');
  var $window = $(window);
  var $images =         $('[data-intro-animation-image]', $host),
      $text =           $('[data-intro-animation-text]', $host).find('p'),
      $imageContainer = $('[data-intro-animation-container]', $host);

  // State

  var state = {
    open: !$host.hasClass('is-collapsed'),
    animated: false
  };

  var callbacks = {
    click: onClickHandlers || []
  };

  var parallaxWidgets = $('[data-widget="parallax"]', $host).toArray().map(getWidgetAtDom).filter(id);

  // Self-reference

  var self = {
    dom:      $host,
    name:     config.pane,
    inner:    $inner,
    index:    0,
    adjust:   adjust,
    expand:   expand,
    prepare:  prepare,
    collapse: collapse,
    playIntro: playIntro,
    resetIntro: resetIntro,
    isOpen: function () { return state.open; }
  };

  function calcHeight () {
    return $inner.outerHeight() + $header.outerHeight();
  }

  function prepare () {
    state.open = false;
    $host.css({ height: 0 }).addClass(collapsedClass);
  }

  function collapse () {
    bindParallaxEvents(false);
    state.open = false;
    $host.addClass(collapsingClass);
    TweenLite.to($host, collapseSpeed, {
      height: getCollapsedHeight(), ease: Power4.easeInOut, onComplete: function () {
        $host.addClass(collapsedClass).removeClass(collapsingClass);
      }
    });
  }

  function expand () {
    if (state.open) return red('EXPAND SKIPPED:', self.name);
    var targetHeight = calcHeight();
    bindParallaxEvents(true);
    state.open = true;
    TweenLite.to($host, collapseSpeed, {
      height: targetHeight, clearProps: 'height', ease: Power4.easeInOut, onComplete: function () {
        $host.removeClass(collapsedClass);
      }
    });
  }

  function adjust () {
    return; // Disabling this to see if I can get away with not using it at all
    if (state.open) {
      $host.css({ height: calcHeight() });
    }
  }

  function updateParallaxWidgets () {
    var pos = $(document).scrollTop();
    parallaxWidgets.map(function (pw) { pw.updateScrollPos(pos); });
  }

  function bindParallaxEvents (mode) {
    //red('switch parallax events', (mode ? 'ON' : 'OFF'), 'in pane', self.name.toUpperCase());
    $window[ mode ? 'on' : 'off' ]('scroll', updateParallaxWidgets);
  }

  function bindImageLinkTouchEvents () {
    var $all = $('.image-link', $host);
    $all.on('touchstart',      function () { this.classList.add(imageLinkTouchClass); });
    $(document).on('touchend', function () { $all.removeClass(imageLinkTouchClass); });
  }

  function playIntro() {

    if ($images.length > 0 && config.playIntroAnimation === 'true') {
      new TimelineMax ()
        .staggerTo($images, time * 4, { autoAlpha: 1, ease: ease, delay: time * 2 }, time * 1.5)
        .to($images, time * 4, { autoAlpha: 0, ease: ease })
        .to($text, time * 2, { autoAlpha: 1, ease: ease, y: 0 }, "-=" + time * 1.4);

      self.animated = true;
    }

    if (typeof config.playIntroAnimation === 'undefined' || config.playIntroAnimation === 'false') {
      disableIntro();
      resetIntro();
    }
  }

  function resetIntro() {
    TweenLite.set($text, { autoAlpha: 1, y: 0 });
    TweenLite.set($images, { clearProps: 'opacity, visibility, transform' });
  }

  function disableIntro() {
    TweenLite.set($imageContainer, { height: 'auto' });
  }

  // Listeners

  $header.on('mousedown', function (event) {
    event.preventDefault(); // Prevent text selection just on header
  });

  $host.on('click', function () {
    if (!state.open) {
      callbacks.click.map(invoke(self));
    }
  });

  $window.on('resize', adjust);


  // Interface

  self.onClick = function (λ) {
    callbacks.click.push(λ);
  };

  self.clone = function () {
    var newPane = Pane(self.dom.clone().addClass(collapsedClass), config, callbacks.click);
    return newPane;
  };

  self.injectHeader = function () {
    state.open = false;
    self.dom
      .css({ height: 0, minHeight: 0 })
      .addClass(collapsedClass);
    TweenLite.to(self.dom, collapseSpeed, { height: getCollapsedHeight(), minHeight: getCollapsedHeight(), clearProps: 'minHeight' });
    self.initWidgets();
  };

  self.removeHeader = function () {
    TweenLite.to(self.dom, collapseSpeed, { height: 0, minHeight: 0, ease: Power4.easeInOut, onComplete: removeTarget });
  };

  self.collapseAndRemove = function () {
    state.open = false;
    TweenLite.to($host, collapseSpeed, { height: 0, ease: Power4.easeInOut, onComplete: removeTarget });
  };

  self.isCollapsing = function () {
    return !state.open;
  };

  self.adjustHeight = function () {
    if (state.open) {
      $host.css({ height: calcHeight() });
    }
  };

  self.isModal = function () {
    return isdef(config.modal);
  };

  self.replaceContent = function ($newDom, skipAnimation) {
    $host.html($newDom.html());
    $header = $host.find('.pane-header');
    $inner  = $host.find('[data-pane-content]');
    self.initWidgets();

    log('skipAnimation?', !!skipAnimation);

    if (skipAnimation) {
      TweenLite.fromTo($host.children(), collapseSpeed, { alpha: 0.4 }, { alpha: 1 });
    } else {
      TweenLite.fromTo($header, collapseSpeed, { alpha: 0.4 }, { alpha: 1 });
      TweenLite.fromTo($inner,  collapseSpeed, { alpha: 0.4, y: 80 }, { alpha: 1, y: 0, ease: Power4.easeOut });
    }
  };

  self.initWidgets = function () {
    initAllWidgetsWithin($host);
    if (config.controller) initController(config.controller, $host);
    parallaxWidgets = $('[data-widget="parallax"]', $host).toArray().map(getWidgetAtDom);

    if (state.open) {
      bindParallaxEvents(true);
      updateParallaxWidgets($(document).scrollTop());
    }

    bindImageLinkTouchEvents();
  };


  // Init

  if (state.open) {
    bindParallaxEvents(true);
    updateParallaxWidgets($(document).scrollTop());
    bindImageLinkTouchEvents();
  }


  // Export

  return self;

};
