
// Require

var PaneConstructor = require('../widgets/pane');
var scrollSpeed     = 1.25;
var CART_MODAL_ID   = 'store/cart';


// Meta

window.__controller_pane_init =
  typeof window.__controller_pane_init !== 'undefined'
    ? window.__controller_pane_init
    : false;


// Metahelpers

function isModal (pane) {
  return pane.isModal();
}

function isNotModal (pane) {
  return !pane.isModal();
}

function getPaneInfo (pane) {
  return pane.isModal() ? pane.name : pane.name.toUpperCase();
}

function scrollToAnchorIfFound (pane, anchor) {

  log('scrollToAnchorIfFound', pane.name, anchor);

  // Process anchor to remove string clutter
  if (!anchor || !isdef(anchor) || anchor == '#' || anchor == '') return scrollTo(0, scrollSpeed);
  anchor = anchor.replace('#', '');

  // Start by assuming target will not be found
  var $target = [];

  // If we find a matching anchor, we want to measure it's offset but only after the page is in place
  pane.dom.find('[id]').each(function () { if (this.id == anchor) { $target = $(this); } });

  // Measure scroll times so we can argue about them
  var scrollStart = Date.now();
  purple('starting pane scroll now');

  // Scroll to the top regardless
  scrollTo(0, scrollSpeed, function () {
    purple('pane scroll completed after ' + ((Date.now() - scrollStart)/1000)+'s');
    scrollStart = Date.now();

    // If we also found a matching anchor, wait a bit, then go there as well
    if ($target.length) {
      delay(scrollSpeed * 0.2, function () {
        purple('starting anchor scroll ' + ((Date.now() - scrollStart)/1000)+'s', 'after pane scroll finished');
        scrollStart = Date.now();

        var dest = getElementOffsetExcludingGSAPTransforms($target) - 40;
        scrollTo(dest, scrollSpeed * 0.8, function () {
          purple('anchor scroll completed after ' + ((Date.now() - scrollStart)/1000)+'s');
        });
      });
    }
  });
}

function generateModal (name, height) {
  height = height || 500;
  return $(
    '<section class="pane is-modal" data-widget="pane" data-pane="' + name.toLowerCase().replace(/ /g, '-') + '" data-theme="error">' +
      '<div class="pane-inner pane-header" data-layout="cols three">' +
        '<h2 data-width="2">' + name + '</h2>' +
        '<a data-width="1" data-pane-trigger="contact">Contact</a>' +
      '</div>' +
      '<div class="pane-inner" data-pane-content style="height:' + height + 'px"></div>' +
    '</section>'
  );
}


// Gen std modals

var STANDARD_MODALS_404 = generateModal('404 Not Found', 500);



//
// Pane Controller
//

module.exports = function PaneController ($page, $$) {

  // Only run once

  if (window.__controller_pane_init) return console.warn('PaneController tried to run twice - blocking second attempt.');
  window.__controller_pane_init = true;

  // Pane setup

  var served = $$('[data-pane]').toArray().map(getWidgetAtDom);
  var panes  = served.filter(isNotModal);
  var modals = served.filter(isModal);

  //dumpAllPanes();

  for (var i = 0, m = panes.length - 1; i < m; i++) {
    var newPane = panes[i].clone();
    $page.append(newPane.dom);
    panes.push(newPane);
  }


  // State

  var state = {
    currentPane:  null,
    currentModal: null,
    pageView: {},
    state: 0
  };


  // Functions

  function pushHistory (url, skip) {
    url = ('/' + url).replace('//', '/'); // ALWAYS submit history states WITH a leading slash

    if (false) return red('History OFF:', url);
    if (skip) return red('History SKIP:', url);

    red('History PUSH:', url);
    history.pushState({ url: url }, "NEW TITLE", url);

    // If GA is defined properly, push pageview event
    red('GA page view:', url);
    if (ga) ga('send', 'pageview', url);
  }

  function popHistory (event) {
    if (event.state && event.state.url) {
      resolvePaneRequest(event.state.url, true);
    } else {
      resolvePaneRequest('featured', true);
    };
  };

  function logPaneAndHash (method, name, hash) {
    blue('PaneController::' + method + ' -', name, '(with', hash ? 'hash "' + hash + '")' : 'no hash)');
  }

  function dumpAllPanes () {
    blue('Served:', served.map(getPaneInfo));
    blue('Panes: ', panes.map(getPaneInfo));
    blue('Modals:', modals.map(getPaneInfo));
  }

  function middlePane () {
    return panes[ Math.floor(panes.length/2) ];
  }

  function cycleUp (newPane) {
    panes.shift().removeHeader();
    panes.push(newPane);
    $page.append(newPane.dom);
    newPane.injectHeader();
  }

  function cycleDown (newPane) {
    panes.pop().removeHeader();
    panes.unshift(newPane);
    $page.prepend(newPane.dom);
    newPane.injectHeader();
  }

  function buildPaneRequestFromDom () {
    var target = $(this).data('pane-trigger');
    var prefix = $(this).data('pane-prefix');

    switch (target.split('/').length) {
      case 0:  console.warn('PaneController::onPaneTrigger - missing target?', target); break;
      case 1:  target = prefix + '/' + target; break;
      case 2:  /* No action required */ break;
      default: console.warn('PaneController::onPaneTrigger - malformed target', target); break;
    }

    return resolvePaneRequest(target);
  }

  function resolvePaneRequest (request, noHistoryWrite) {

    // NEVER submit pane requests with a leading slash
    request = stripLeadingSlashes(request);

    blue('PaneController::resolvePaneRequest - ' + request);

    // Ignore non-critical parts of the url
    var parts  = request.split('#');
    var target = parts[0];
    var hash   = parts.length > 1 ? parts[1] : '';

    // Look to see if we already have that pane
    var match = panes.filter(function (pane) { return pane.name === target; });

    // If we found one, make it active and stop
    if (match.length) {
      blue('PaneController::resolvePaneRequest - found existing pane. Stopping.');
      return setActive(head(match), hash, noHistoryWrite);
    } else {
      blue('PaneController::resolvePaneRequest - no existing panes found.');
    }

    // Look to see if we already have it as a modal
    var match = modals.filter(function (pane) { return pane.name === target; });

    // If we found one, make it active and stop
    if (match.length) {
      blue('PaneController::resolvePaneRequest - found existing modal. Stopping.');
      return setActive(head(match), hash, noHistoryWrite);
    } else {
      blue('PaneController::resolvePaneRequest - no existing modals found.');
    }

    // Otherwise, we assume we have to summon this as a modal.
    fetchModalAndInjectNewCopy(target, hash);
  }


  //
  // Ajax Fetch
  //

  function fetchModal (target, hash, onSuccess) {
    red('fetchModal:', target);

    $.ajax({
      url: ('/' + target).replace('//', '/'),  // Add leading slash but only if it's not there
      type: 'get',
      data: { ajax: true },
      datatype: 'html',
      success: function (data) {
        if (data.length === 0) {
          injectModal(STANDARD_MODALS_404, '', true);
        } else {
          onSuccess($(data));
        }
      },
      error: function (err, x, y) {
        injectModal(STANDARD_MODALS_404, '', true);
      }
    });
  }

  function fetchModalAndInjectNewCopy (target, hash) {
    fetchModal(target, hash, function ($data) {
      injectModal($data, hash);
    });
  }

  function fetchModalAndReplaceContents (target, hash) {
    fetchModal(target, hash, function ($data) {
      replaceModalContents($data, target);
    });
  }


  //
  // Insert and update modal panes
  //

  function injectModal ($modal, hash, noHistoryWrite) {

    var name = $modal.data('pane');
    logPaneAndHash('injectModal', name, hash);

    state.currentPane.collapse();

    var injectionPoint = state.currentModal ? state.currentModal : middlePane();
    var pane = PaneConstructor($modal, $modal.data(), [ setActive ]);
    pane.initWidgets();

    pane.prepare();
    pane.dom.insertAfter(injectionPoint.dom);
    pane.expand();

    if (state.currentModal) {
      if (!state.currentModal.isCollapsing()) {
        state.currentModal.collapse();
      }
    }

    state.currentModal = pane;
    modals.push(pane);
    pushHistory(pane.name, noHistoryWrite);
    scrollToAnchorIfFound(pane, hash);
  }

  function replaceModalContents ($content, target) {
    var match = modals.filter(function (pane) { return pane.name === target; });

    if (match.length) {
      match[0].replaceContent($content);
    } else {
      red('Couldnt find modal to replace contents of -', target);
    }
  }

  function updatePageViewState(paneName) {
    if (isdef(state.pageView[paneName])) {
      state.pageView[paneName]++;
    } else {
      state.pageView[paneName] = 0;
    }
  }

  function closeTopmostModal () {
    red('closeTopmostModal', modals);

    if (modals.length < 1) return console.warn('PaneController::closeTopmostModal - no modals in stack');

    scrollTo(0, scrollSpeed);

    delay(scrollSpeed * 1.3, function () {
      // If there was another modal underneath, it is now the active one
      if (modals.length > 1) {
        red('closeTopmostModal - detected other modal:', modals);
        setActive(modals[modals.length - 2], '', false);
      // Otherwise use the current non-modal pane
      } else {
        red('closeTopmostModal - go back to base pane:', state.currentPane);
        setActive(state.currentPane, '', false)
      }
    });
  }

  function setActive (pane, hash, noHistoryWrite) {

    logPaneAndHash('setActive', pane.name, hash);

    updatePageViewState(pane.name);
    pushHistory(pane.name, noHistoryWrite);

    // If it is a modal, and some modals are present, dismiss all modals
    // in front of the incoming one, and stop.

    if (pane.isModal() && modals.length) {
      for (var i = modals.length - 1; i >= 0; i--) {
        if (modals[i] !== pane) {
          var lastModal = modals.pop();
          lastModal.collapseAndRemove();
          state.currentModal = modals[ modals.length - 1 ];
        } else break;
      }

      state.currentModal.expand();
      scrollToAnchorIfFound(state.currentModal, hash);
      return;
    }

    // If incoming pane is NOT a modal, but some modals are present,
    // dismiss ALL modals and carry on to change the pane.

    if (!pane.isModal() && modals.length) {
      var modal;
      while (modal = modals.pop()) {
        modal.collapseAndRemove();
      };
    }


    // If there are any modals at all

    if (state.currentModal) {

      // If the incoming pane is the current modal itself, no work is required
      if (pane === state.currentModal) return scrollToAnchorIfFound(pane, hash);

      // Otherwise, remove this modal and carry on to pick correct pane
      state.currentModal.collapseAndRemove();
      state.currentModal = null;

      // If we removed a modal but we need to reinstate the previously-current pane, do so and then bail
      if (pane === state.currentPane) {
        if (modals.length) {
          modals.pop().collapseAndRemove();
        } else {
          state.currentPane.expand();
        }
      }
    }

    // Find distance between current and destination panes
    var Δ = panes.indexOf(state.currentPane) - panes.indexOf(pane);

    // Play Intro animations if we have one.
    if (state.pageView[pane.name] < 1) {
      pane.playIntro();
    } else {
      pane.resetIntro();
    }

    // Bail if Δ is zero, cos there's no work to do
    if (Δ === 0) return; //console.warn("PaneController::setActive - Δ is zero, no work to do");

    // Swap enough panes to get from here to destination pane
    for (var i = 0; i < Math.abs(Δ); i++) {
      (Δ < 0) ? cycleUp(middlePane().clone()) : cycleDown(middlePane().clone());
    }

    // Scroll new pane into view
    scrollToAnchorIfFound(pane, hash);

    // Animate
    state.currentPane.collapse();
    pane.expand();
    state.currentPane = pane;
  }


  // Listeners

  served.map(function (pane) { pane.onClick(setActive); });
  $page.on('click', '[data-scroll-to-contact-trigger]', swallow(scrollToBottom(scrollSpeed)));
  $page.on('click', '[data-close-modal-pane-trigger]', swallow(closeTopmostModal));
  $page.on('click', '[data-pane-trigger]', swallow(buildPaneRequestFromDom));
  window.onpopstate = popHistory;


  // Init (make the tracked state match the initial delivered HTML)

  if (modals.length < 1) { // If there are no modals initially, select the first 'open' pane

    state.currentPane = head(panes.filter(function (it) { return it.isOpen(); }));

  } else { // Otherwise if we got served any modals...

    // First select the latest one as the current modal.
    state.currentModal = modals[ modals.length - 1 ];

    // We can then infer that the current pane is necessarily the parent of the
    // earliest modal, even tho the server didn't bother to mark it as open.
    state.currentPane = served[0];

    // Step through all the panes until the first modal is found - when we find
    // it, the next-most-recently selected pane must be the parent we want.
    for (var i = 1; i < served.length; i++) {
      if (served[i] === modals[0]) break;
      state.currentPane = served[i];
    }
  }

  defer(function () {
    if (state.currentModal) { // If we selected a modal, it is the active pane.
      setActive(state.currentModal, window.location.hash, true);
    } else if (state.currentPane) { // Otherwise, assume we got served an open pane
      setActive(state.currentPane, window.location.hash, true);
    } else {                        // Also, never write the first page to the history
      console.error('PaneController::Init - no un-collpased panes detected.');
    }
  });

  $(window).trigger('resize');


  // Interface

  return {
    showCartModal: function ($cartHtml) {
      red('PaneController::showCartModal');

      // If we're already showing a cart modal, munge it's contents instead of replacing it
      var match = modals.filter(function (pane) { return pane.name === CART_MODAL_ID; });

      log('showCartModal');

      if (match.length) {
        scrollTo(0, scrollSpeed * 0.6, function () {
          match[0].replaceContent($cartHtml, true);
        });

      } else {
        injectModal($cartHtml);
      }
    }
  };

};
