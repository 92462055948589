
//
// Import Widget Library
//

var Widgets = {
  'filter-categories':      require('./filter-categories'),
  'filter-list':            require('./filter-list'),
  'live-variant-price':     require('./live-variant-price'),
  'pane':                   require('./pane'),
  'parallax':               require('./parallax'),
  'product-option':         require('./product-option'),
  'project-rollovers':      require('./project-rollovers'),
  'quantity-adjust':        require('./quantity-adjust'),
  'shipping-address-clone': require('./shipping-address-clone'),
  'text-follows-cursor':    require('./text-follows-cursor'),
  'image-hover':            require('./image-hover'),
};


//
// Widget Metasystem
//

var allWidgets = [];
var genWid = (function (i) { return function () { return i++; }; }(0));

window.getWidgetById = function getWidgetById (wid) {
  return allWidgets[wid];
};

window.getWidgetAtDom = function getWidgetAtDom (el) {
  return getWidgetById($(el).attr('data-widget-id'));
}

window.initAllWidgetsWithin = function initAllWidgetsWithin (subdom) {
  //var data = (subdom.data) ? subdom.data() : subdom.length ? subdom[0].dataset : subdom.dataset;
  //green('initAllWidgetsWithin:', subdom, data ? '<' + data.pane + '>' : data);
  [].reverse.apply( $('[data-widget]', subdom) ).toArray().forEach(initWidget);
}

function initWidget (el) {
  var types = el.dataset.widget.split(' '), config = el.dataset;

  for (var i in types) {
    var type = types[i];
    if (Widgets[type]) {
      var wid = genWid();

      //if ( $(el).attr('data-widget-id') ) {
        //red('New Widget:', 'Found widget but it has already been initialised:', type);
      //} else {
        //green('New Widget:', type);
        allWidgets[wid] = Widgets[type]($(el), config);
        $(el).attr('data-widget-id', wid);
      //}
    } else {
      console.warn('No such widget:', type);
    }
  }

  return allWidgets[wid];
}


//
// Export
//

module.exports = {
  initAllWithin: initAllWidgetsWithin
};
