
window.id = function _id (n) {
  return n;
};

window.log = function _log () {
  console.log.apply(console, arguments);
  return arguments[0];
};

window.red    = logColor('red');
window.green  = logColor('#0b2');
window.blue   = logColor('blue');
window.purple = logColor('#e4b');

window.head = function head (xs) {
  return xs.length ? xs[0] : null;
};

window.delay = function delay (t, λ) {
  var n = setTimeout(λ, t * 1000);
  return { cancel: function () { clearTimeout(n); } };
};

window.defer = function defer (λ) {
  return setTimeout(λ, 0);
};

window.invoke = function () {
  var args = arguments;
  return function (λ) {
    λ.apply(null, args);
  }
};

window.findInside = function findInside (el) {
  return function (sel) {
    return $(sel, el);
  };
};

window.whichKey = function whichKey (code, λ) {
  return function _whichKey (event) {
    if (event.which === code) {
      λ(event);
    }
  };
};

window.removeSelf = function removeSelf () {
  $(this).remove();
}

window.dot = function dot (prop) {
  return function (thing) {
    return thing[prop];
  };
};

window.clone = function clone (obj) {
  return Object.assign({}, obj);
};

window.isdef = function isdef (thing) {
  return typeof thing !== 'undefined';
}

window.swallow = function swallow (λ) {
  return function _swallow (event) {
    event.preventDefault();
    event.stopPropagation();
    λ.apply(this, arguments);
  };
}

$.easing.easeOutQuad = function (x, t, b, c, d) {
		return -c *(t/=d)*(t-2) + b;
	};

window.stripLeadingSlashes = function stripLeadingSlashes (text) {
  return text.replace(/^\/*/, '');
}

function JQscrollTo (offset, speed) {
  var current = $('html, body').scrollTop();
  speed = speed ? speed : 0.3;
  green('scrollTo(jQuery): from', current, 'to', offset, 'at', speed);

  // Animate
  $('html, body').animate({ scrollTop: offset }, speed * 1000, 'easeOutQuad');
}

function GSAPscrollTo (offset, speed, λ) {
  var current = Math.max(document.documentElement.scrollTop, document.body.scrollTop);
  speed = speed ? speed : 0.3;

  if (current === offset) {
    if (λ) λ();
    return green('scrollTo(GSAP): don\'t bother scrolling from ', current, 'to', offset);
  } else {
    green('scrollTo(GSAP): from', current, 'to', offset, 'at', speed);
  }

  // Animate
  TweenLite.to({ z: current }, speed, {
    z: offset,
    ease: Power4.easeInOut,
    onComplete: λ || id,
    onUpdate: function () { document.body.scrollTop = document.documentElement.scrollTop = this.target.z; }
  });
}

window.scrollTo = GSAPscrollTo;

window.scrollToBottom = function scrollToBottom (speed) {
  return function _scrollToBottom () {
    scrollTo( getPageHeight() - window.innerHeight, speed );
  }
}

window.getElementOffsetExcludingGSAPTransforms = function getElementOffsetExcludingGSAPTransforms ($el) {
  return $el.offset().top - ($el[0]._gsTransform ? $el[0]._gsTransform.y : 0);
}

window.getPageHeight = function getPageHeight () {
  var body = document.body, html = document.documentElement;
  return Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
}

window.jqe = function (λ) {
  return function _jqe (event) {
    λ(this, event);
  };
}

window.formatMoney = function formatMoney (value) {
  if (typeof value === 'number') return value;
  return '$' + parseFloat(value, 10).toFixed(2);
}

/*
Array.prototype.contains = function contains (list, needle) {
  return -1 < list.indexOf(needle);
}

window.compose = function compose (f, g) {
  return function () {
    g(f.apply(null, arguments));
  };
};

*/

// Fetch Json in regulated manner

window.fetchJson = function fetchJson (url, success, error) {

  function receive (response) {
    if (response.error) {
      error(response.reason);
    } else {
      success(response.response);
    }
  }

  function onload () {
    try {
      receive(JSON.parse(xhr.response));
    } catch (e) {
      receive({ error: true, reason: "JSON Parse error: " + e });
    }
  }

  function onerror (err) {
    if (xhr.status !== 200) {
      receive('{ "error": true, "reason": "HTTP error: ' + xhr.status + '" }');
    } else {
      receive('{ "error": true, "reason": "JS error: ' + err + '" }');
    }
  }

  // Dispatch
  var xhr = new XMLHttpRequest();
  xhr.onload = onload;
  xhr.onerror = onerror;
  xhr.open("GET", url);
  xhr.send();

};


// Non-exported metahelpers
function logColor (c) {
  return function _logColor () {
    var args = [].slice.apply(arguments);
    var first = args.shift();
    args.unshift('color:' + c);
    args.unshift('%c' + first);
    console.log.apply(console, args);
    return first;
  };
}

