
module.exports = function ImageHover ($host, config) {

  // Constants

  var activeClass = 'is-active';


  // Dom

  var $host = $host;


  // Functions

  function manageHover (state) {
    state ? $host.addClass(activeClass) : $host.removeClass(activeClass);
  }


  // Listeners

  $host.on('mouseenter', function () { manageHover(true) });
  $host.on('mouseleave', function () { manageHover(false) });

};
