
module.exports = function ProductController ($page, $$) {

  log('ProductController');

  // We output the product option dropdowns and variant listing outside of where they should be due
  // to some limitations of templating with blocks. We need to pick them out of their hiding place
  // and put them back down where we want them, and make sure they widget stays working and intact
  // at teh same time. Then, connect the dropdowns to the live-price widget using the json data
  // so that the price can respond to the variant which results from the dropdown selection.

  // Setup
  var skuBase  = $$('[data-variant-prices]').data('sku-base');
  var variants = JSON.parse($$('[data-variant-prices]').text() || '[]');

  // Dom
  var $optionHost = $$('[data-product-options-host]');

  // Widgets
  var options   = $$('[data-widget="product-option"]').toArray().map(getWidgetAtDom);
  var livePrice = getWidgetAtDom($$('[data-widget~="live-variant-price"]'));

  // Helpers
  function optionGetValue   (option) { return option.getValue(); }
  function optionBindUpdate (option) { return option.onChange(updateSku); }
  function optionMoveHost   (option) { return option.moveDomTo($optionHost); }

  // Functions
  function buildSku () {
    return skuBase + '-' + options.map(optionGetValue).join('-');
  }

  function updateSku () {
    var sku = buildSku();
    var matches = variants.filter(function (v) { return v.sku === sku; });

    if (matches.length) {
      var variant = matches[0];
      if (livePrice) livePrice.setPrice(variant.price);
    } else {
      console.warn("ProductController::updateSku - no matching variant for synthesized sku:", sku);
    }
  }

  // Init (unless there are no variants at all)
  if (skuBase) {
    options.map(optionMoveHost).map(optionBindUpdate);
    updateSku();
  }

}

