
module.exports = function LiveVariantPrice ($host, config) {

  // Dom
  var $price = $('[data-live-price]', $host);

  // Helpers
  function setPrice (price) {
    blue('setPrice', price);
    $price.text(formatMoney(price));
  }

  // Interface
  return {
    setPrice: setPrice
  };

};

