
module.exports = function TextFollowsCursor ($host, config) {

  // Setup
  var padding = 30;
  var lerpTime = 0.5;
  var fingerWidth = 200;

  // Dom
  var $text = $('.rollover-text', $host);

  // Functions
  function normaliseMouse (λ) {
    return function _normaliseMouse (event) {
      var offset = $host.offset();
      return λ({
        x: event.pageX - offset.left,
        y: event.pageY - offset.top,
        ease: true
      });
    }
  }

  function normaliseTouch (λ) {
    return function _normaliseTouch (event) {
      var offset = $host.offset();
      return λ({
        x: event.touches[0].pageX - offset.left,
        y: event.touches[0].pageY - offset.top,
        ease: false
      });
    }
  }

  function follow (position) {
    var width  = $host.outerWidth();
    var height = $host.outerHeight();
    var textW  = $text.outerWidth();
    var textH  = $text.outerHeight() + parseInt($text.css('margin-top')) + parseInt($text.css('margin-bottom'));
    var y      = Math.max(0, Math.min( position.y - textH/2 - padding, height - textH - padding * 2 ));
    //var destX  = Math.max(0, Math.min( position.x - textW   - padding, width  - textW )); // No X for now

    if (position.ease) {
      TweenLite.to($text, lerpTime, { y: y, ease: Power4.easeOut });
    } else {
      TweenLite.set($text, { y: y });
    }
  }

  // Listeners
  $host.on('mousemove',            normaliseMouse(follow));
  $host.on('touchstart touchmove', normaliseTouch(follow));

}

