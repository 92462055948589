
//
// Global Controller
//
// Joins other controller's behaviours together
//

module.exports = function GlobalController () {

  // State

  var controllers = {
    cart: null,
    panes: null
  };


  // Functions

  function onControllerAvailable (type, controller) {
    controllers[type] = controller;
    // red('GlobalController::onControllerAvailable -', type, controller);

    if (!!controllers.cart && !!controllers.panes) {
      controllers.cart.onCartUpdated(controllers.panes.showCartModal);
    }
  }


  // Interface

  return {
    onControllerAvailable: onControllerAvailable
  }

}
