
var openClass = 'is-open';



//
// Shipping address cloner
//

module.exports = function ShippingAddressCloner ($host, config) {

  green('ShippingAddressCloner');

  // Dom
  var $shippingParent = $('[data-shipping-address-inputs]',               $host);
  var $toggleCheckbox = $('[data-shipping-address-toggle]',               $host);
  var $billingInputs  = $('[data-billing-address-inputs]  input, select', $host);
  var $shippingInputs = $('[data-shipping-address-inputs] input, select', $host);

  // State
  var state = { cloning: true };

  // Fucntions
  function cloneAll () {
    $billingInputs.each(function (ix, el) { cloneInput(el); });
  }

  function cloneInput (input, event) {
    if (!state.cloning) return;
    var $target = $shippingInputs.filter('[name="shipping_' + input.name + '"]');
    if ($target.length === 1) {
      log('ShippingAddressCloner::cloneInput -', input.name, '→', $target[0].name);
      $target.val(input.value);
    }
  }

  function toggleCloning (input) {
    state.cloning = !input.checked;
    if (state.cloning) cloneAll();
    $shippingParent.toggleClass(openClass, !state.cloning);
  }

  // Listeners
  $billingInputs.on('change', jqe(cloneInput));
  $toggleCheckbox.on('change', jqe(toggleCloning));

  // DEBUG - fill inputs and listen for change events
  if (window.location.hostname === 'localhost') {
    delay(0.5, function () {
      $billingInputs.each(function (ix, el) {
        if (this.type === 'hidden') return;

        delay(0.1 * ix, function () {
          switch (this.name) {
            case 'first_name' : this.value = "Jordan"; break;
            case 'last_name'  : this.value = "GhostSt"; break;
            case 'email'      : this.value = "jordan@ghost.st"; break;
            case 'company'    : break;
            case 'address_1'  : this.value = "150 Anywhere St"; break;
            case 'address_2'  : break;
            case 'county'     : this.value = "CBD"; break;
            case 'city'       : this.value = "Auckland"; break;
            case 'postcode'   : this.value = "1010"; break;
            default: break;
          }
          $(this).trigger('change');
        }.bind(this));
      });
    });
  }

}
