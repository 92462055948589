
//
// Cart Ajax Controller
//
// Stripe is disabled for now. To re-enable, add the handler listener back to [data-payment-button]
// and it will fully refresh the page when done. This isn't ideal but I'm not sure how to capture
// the process properly like we did for Manual processing.
//

module.exports = function CartController ($page, $$) {

  // Stripe Setup
  /*var stripeHandler = StripeCheckout.configure({
    key:   'pk_test_QbkFXd5yFam53d0uz7cWJeO8',
    image: '/assets/img/logo-stripe.png',
    locale: 'auto',
    token: function (tokenResponse) {
      green('GOT STRIPE TOKEN:', tokenResponse.id);

      var $paymentForm = $(
        '<form action="/checkout/checkout.php" method="post" data-payment-form>' +
          '<input type="hidden" name="stripeToken" value="' + tokenResponse.id + '" />' +
        '</form>'
      );

      $page.append($paymentForm);
      $paymentForm.submit();
    }
  }); */

  // State
  var state = { cartState: null };
  var callbacks = { updated: id };

  // Functions
  function updateCart (rawHtml) {
    console.groupCollapsed('Received HTML');
    console.log(rawHtml);
    console.groupEnd();
    callbacks.updated($(rawHtml));
    fetchCartState();
  }

  function saveCartState (cartState) {
    green('Cart updated:', cartState.item_count, 'items at', formatMoney(cartState.grand_total));
    green('Shipping method:', '#' + cartState.shipping_id + ': "' + cartState.shipping_method + '"', cartState);
    state.cartState = cartState;
    $$('[data-cart-summary]').each(updateCartSummary(cartState));
  }

  function updateCartSummary (cartState) {
    if (cartState.item_count) {
      return function (ix, el) {
        el.style.visibility = 'visible';
        el.textContent = formatCartSummary(cartState);
      };
    } else {
      return function (ix, el) {
        el.style.visibility = 'hidden';
        el.textContent = 'Cart';
      }
    }
  }

  function formatCartSummary (cartState) {
    return 'Cart (' + cartState.item_count + ') ' + cartState.total_items_formatted.replace(/\.\d\d/, '');
  }

  function showErrors (response, errorType, error) {
    red('CART ERROR:', response.responseText);
  }

  function fetchCartState () {
    $.ajax({
      url:      '/checkout/json.php',
      type:     'get',
      dataType: 'json',
      success:  saveCartState,
      error:    showErrors
    });
  }

  function fetchAsAjax (url) {
    $.ajax({
      url:      url,
      type:     'get',
      data:     { ajax: true },
      dataType: 'html',
      success:  updateCart,
      error:    showErrors
    });
  }

  function submitAsAjax (url, formData) {
    formData.push({ name: 'ajax', value: true });

    log('CartController::submitAsAjax -', url, formData);

    // DEBUG
    formData.map(function (m) { blue( m.name + " -> " + m.value) });

    // Do ajax. We expect html content to update the cart pane with
    $.ajax({
      url:      url,
      type:     'post',
      dataType: 'html',
      data:     $.param(formData),
      success:  updateCart,
      error:    showErrors
    });
  }

  function getNearestFormFromEvent (λ) {
    return function _getNearestFormFromEvent (event) {
      var $form    = $(this).closest('form'),
          url      = $form.attr('action'),
          formData = $form.serializeArray();

      // Push any value from the button itself
      if (this.name) formData.push({ name: this.name, value: this.value });

      // Send to callback
      λ(url, formData);

      // Swallow event
      event.preventDefault();
      return false;
    };
  }

  function handlePaymentButton () {
    handler.open({
      name: 'Think + Shift',
      description: state.cartState.item_count + ' ' + (state.cartState.item_count === 1 ? 'item' : 'items') + ', ' + state.cartState.grand_total_formatted,
      zipCode: true,
      amount: parseFloat(state.cartState.grand_total, 10) * 100
    });
  }

  function submitShipping () {
    $('[data-shipping-submit]').click();
  }

  // Listeners
  $page.on('click',  '[type="submit"]', getNearestFormFromEvent(submitAsAjax));
  $page.on('click',  '[data-checkout-ajax]', swallow(function () { fetchAsAjax(this.href, []); }));
  $page.on('change', '[data-cart-autoupdate-on-change] input', getNearestFormFromEvent(submitAsAjax));
  $page.on('change', '[data-cart-autoupdate-on-change] select', getNearestFormFromEvent(submitAsAjax));
  $page.on('change', 'select[name="shipping"]', submitShipping);
  //$page.on('click',  '[data-payment-button]', swallow(handlePaymentButton));

  // Get initial cart state
  fetchCartState();

  // Interface
  return {
    onCartUpdated: function (λ) { callbacks.updated = λ; }
  };

};

