
//
// Debug Controller
//

module.exports = function DebugController ($page, $$) {


}

