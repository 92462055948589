
// Setup

var offsetThreshold = 540;  // Window width threshold to ignore offsets
var speed           = 1;    // How quickly the parallax moves relative to scroll
var offsetScale     = 3;    // How much the offset results in actual displacement


//
// Parallax Widget
//

module.exports = function ParallaxWidget ($host, config) {

  // Disable parallax for debug
  if (false) return { updateScrollPos: id };

  // Setup
  var offset = config.parallaxOffset ? parseFloat(config.parallaxOffset, 10) : 0;

  // State
  var state = { lastMeasuredWidth: window.innerWidth };

  // Functions
  function clamp (n) {
    return (n < 0) ? 0 : (n > 1) ? 1 : n;
  }

  function mapRatio (r) {
    var q = state.lastMeasuredWidth < offsetThreshold ? 0 : offset;
    var p = clamp((r - 0.5 + q) * (speed + q * offsetScale)); // From 0.75 to 0.5 of screen height
    return p;
    //return Math.pow((p), 4); // Ease out, quadratic
    //return 1 - Math.pow((1 - p), 2); // Ease in, quadratic
  }

  function update (pos) {
    var top = getElementOffsetExcludingGSAPTransforms($host);
    var p   = mapRatio((top - pos) / window.innerHeight);
    TweenLite.to($host, 0.4, { y: window.innerHeight/3 * p, alpha: (1), overwrite: true, ease: Power4.easeOut });
  }

  // Listeners
  $(window).on('resize', function () { state.lastMeasuredWidth = window.innerWidth });

  // Init
  update($(document).scrollTop());

  // Interface
  return {
    updateScrollPos: update
  }
}

