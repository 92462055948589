//return console.warn('JS disabled for testing.');

//
// Load helpers into global namespace
//

require('./helpers');


// jQuery Mods

jQuery.fn.reverse = [].reverse;


//
// Init Widgets and Controllers
//

var Widgets     = require('./widgets');
var Controllers = require('./controllers');

Widgets.initAllWithin(document);
Controllers.initAllWithin(document);


// DEBUG LAYOUT

if (false) {
  var cols    = [];
  var $target = $('.pane-inner').eq(0);
  var gutter  = 30;
  var colW    = 100;

  for (var i = 0; i < 12; i++) {
    var r = Math.floor(i * (255/12)), b = Math.floor((12 - i) * (255/12));
    var div = document.createElement('div');
    div.style.position = 'fixed';
    div.style.top = 0;
    div.style.height = '100vh';
    div.style.backgroundColor = 'rgba(' + r + ', 0, ' + b + ', 0.2)';
    div.textContent = i + 1;
    div.style.color = 'white';
    cols.push(div);
    document.body.appendChild(div);
  }

  function set (w) {
    colW = ((w - gutter * 11)/12);

    for (var i in cols) {
      var div = cols[i];
      div.style.width = colW + 'px';
      div.style.left = 'calc(50% - ' + w/2 + 'px + ' + (i * colW + i * gutter) + 'px)';
    }
  }

  set($target.outerWidth());

  $(window).on('resize', function () { set($target.outerWidth()); });
}
