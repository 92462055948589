
var activeClass = 'is-selected';


//
// Filter Category Triggers
//

module.exports = function FilterCategories ($host, config) {

  // Dom
  var $triggers = $('[data-keyword]', $host);

  // State
  var callbacks = { keyword: id };

  // Listeners
  $triggers.on('click', function () {
    // Only change state if filter request got accepted
    if (callbacks.keyword(this.dataset.keyword)) {
      $triggers.removeClass(activeClass);
      $(this).addClass(activeClass);
    }
  });

  // Interface
  return {
    onKeyword: function (λ) {
      callbacks.keyword = λ;
    }
  };

};

