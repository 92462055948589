
var focusClass = 'is-focused';

var all = [];


module.exports = function ProductOption ($host, config) {

  // Dom
  var $items    = $('input', $host);
  var $current  = $('[data-current-option]', $host);

  // State
  var state     = { open: false };
  var callbacks = { change: [] };

  // Functions
  function update () {
    var $label = $items.filter(':checked').siblings('label');
    getValue();
    callbacks.change.map(function (λ) { λ(getValue()); });
    close();
    $current.text( $label.text() );
  }

  function selectFirst () {
    $items.prop('checked', null).eq(0).prop('checked', true);
    update();
  }

  function getValue () {
    return $items.filter(':checked').data('sku');
  }

  function toggle () {
    if (state.open) { close(); } else { open(); }
  }

  function open () {
    all.map(closeOther);
    state.open = true;
    $current.addClass(focusClass);
  }

  function close () {
    state.open = false;
    $current.removeClass(focusClass);
  }

  function closeOther (other) {
    other.close();
  }

  // Listeners
  $items.on('change', update);
  //$current.on('touchstart', toggle);
  $current.on('click', toggle);

  // Init
  selectFirst();

  // Interface
  var self = {
    close: close,
    getValue: getValue,
    moveDomTo: function (el) { log('ProductOption::moveDomTo', el); $host.appendTo(el); return self; },
    onChange:  function (λ)  { callbacks.change.push(λ); return self; }
  }

  // Meta
  all.push(self);
  return self;

};

