
module.exports = function FilterController ($page, $$) {

  // Widgets
  var triggers = getWidgetAtDom($$('[data-widget="filter-categories"]'));
  var items    = getWidgetAtDom($$('[data-widget="filter-list"]'));

  // Listeners
  triggers.onKeyword(items.filter);

};

